import "./App.css";
import { useState } from "react";
import AutoInput from "./components/autoinput/autoinput";
import Canvas from "./components/canvas/canvas";
import { DatePicker } from "@material-ui/pickers";
import { useEffect } from "react";
import axios from "axios";

function App() {
  const ActualDate = new Date();
  const [CanvasData, setCanvasData] = useState("");
  const [Fecha, setFecha] = useState(new Date());
  const [PatientData, setPatientData] = useState("");
  const [ServiceData, setServiceData] = useState("");
  const [SessionsData, setSessionsData] = useState("");
  const [SiteData, setSiteData] = useState("");
  const [TherapistData, setTherapistData] = useState("");
  const [Services, setServices] = useState([]);

  useEffect(() => {
    if (SiteData === "RIIE en casa") {
      setServices([
        "Fonoaudiología",
        "Psicología",
        "Terapia Física",
        "Terapia ocupacional",
      ]);
    } else if (SiteData === "") {
      setServices([]);
    } else {
      setServices([
        "Fonoaudiología",
        "Integración sensorial",
        "Kinesiotaping",
        "Neuropsicología",
        "Psicología",
        "Rehabilitación cognitiva",
        "Terapia Física",
        "Terapia ocupacional",
        "Terapia Vojta",
        "Psicología familiar",
        "Pediasuit",
        "REB",
        "Dermatología",
        "Pediatría",
        "Nutrición",
        "Optometría",
      ]);
    }
    setServiceData("");
  }, [SiteData]);

  const Send = async (e) => {
    if (
      PatientData.Name === undefined ||
      PatientData.IDNumber === undefined ||
      TherapistData.Name === undefined ||
      TherapistData.IDNumber === undefined ||
      ServiceData === "" ||
      SessionsData === "" ||
      CanvasData === "" ||
      SiteData === ""
    ) {
      alert("Por favor introduzca todos los datos");
    } else {
      try {
        await axios.post("https://firmas.riie.com.co/api.php", {
          PatientName: PatientData.Name,
          PatientIDNumber: PatientData.IDNumber,
          TherapistName: TherapistData.Name,
          TherapistIDNumber: TherapistData.IDNumber,
          Sessions: SessionsData,
          Site: SiteData,
          Signature: CanvasData,
          Service: ServiceData,
          DateTime: Fecha,
        });
        alert("Envio exitoso");
        window.location.replace("https://www.firmas.riie.com.co/", "_blank");
      } catch (err) {
        console.log(err);
      }
    }
  };

  return (
    <div className="container">
      <div className="form-border">
        <div className="form-container">
          <label className="label-title">Bienvenido al sistema de firmas RIIE</label>
          <AutoInput
            table="patients"
            inputname="Paciente:"
            inputdata="Nombre o documento del paciente:"
            minlength={3}
            GetData={setPatientData}
          />
          <label className="form-label">Número de sesiones de 30 minutos:</label>
          <select
            name="Sesion"
            id="Sesiones"
            className="SelectSesiones"
            onChange={(e) => setSessionsData(e.target.value)}
            defaultValue=""
          >
            <option value="" hidden>
              Seleccione el número de terapias
            </option>
            <option value="1">1</option>
            <option value="2">2</option>
          </select>
          <label className="form-label">Sede:</label>
          <select
            name="Sede"
            id="Sedes"
            className="SelectSesiones"
            onChange={(e) => setSiteData(e.target.value)}
            defaultValue=""
          >
            <option value="" hidden>
              Seleccione la sede de su terapia
            </option>
            <option value="Bogotá A">Bogotá A - Cra 11b</option>
            <option value="Bogotá B">Bogotá B - Cra 13</option>
            <option value="Chia">Chia</option>
            <option value="Virtual">Virtual</option>
            <option value="RIIE en casa">RIIE en casa</option>
          </select>
          <label className="form-label">Servicio:</label>
          <select
            className="SelectSesiones"
            onChange={(e) => setServiceData(e.target.value)}
            defaultValue=""
          >
            <option hidden>Seleccione su servicio:</option>
            {Services.map((element) => (
              <option>{element}</option>
            ))}
          </select>
          <AutoInput
            table="therapists"
            inputname="Profesional:"
            inputdata="Nombre o código del profesional:"
            GetData={setTherapistData}
            minlength={-1}
          />
          <label className="form-label">Fecha:</label>
          <DatePicker
            value={Fecha}
            onChange={setFecha}
            className="DatePicker"
            disabled
          />
          <label className="form-label">Firma:</label>
          <Canvas GetData={setCanvasData} />
          <button onClick={Send}>Enviar</button>
          <label className="disclaimer">
            Mediante el diligenciamiento de este formulario, autorizas a
            Rehabilitación Integral Infantil Especializada, con NIT
            900.247.822-3, a dar tratamiento de los datos personales entregados,
            bajo la Ley 1581 de 2012. Tu firma acredita la prestación de las
            sesiones realizadas y será únicamente utilizada para este fin frente
            al convenio de salud.
          </label>
        </div>
      </div>
    </div>
  );
}

export default App;
