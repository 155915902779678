import React from "react";
import Autosuggest from "react-autosuggest";
import { useState, useEffect } from "react";
import axios from "axios";
import "./autoinput.css";

const AutoInput = (props) => {
  const [test, setTest] = useState([]);
  const [data, setData] = useState([]);
  const [input, setInput] = useState("");
  const [seldata, setSeldata] = useState({});

  useEffect(() => {
    const fetchallData = async () => {
      try {
        const res = await axios.get("https://firmas.riie.com.co/api.php", {
          params: { Table: props.table },
        });
        setTest(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchallData();
  }, []);

  const onSuggestionsFetchRequested = ({ value }) => {
    if (input.length > props.minlength) {
      setData(FilterSuggestion(value));
    } else {
      setData([]);
    }
  };

  const FilterSuggestion = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    var filtrado = test.filter((patient) => {
      var textoCompleto = patient.Name + " - " + patient.IDNumber;

      if (
        textoCompleto
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .includes(inputValue)
      ) {
        return patient;
      }
    });

    return inputLength === 0 ? [] : filtrado;
  };

  const onSuggestionsClearRequested = () => {
    setData([]);
  };
  const getSuggestionValue = (suggestion) => {
    return `${suggestion.IDNumber}`;
  };
  const renderSuggestion = (suggestion) => (
    <div className="suggestions" onClick={() => selectData(suggestion)}>
      {`${suggestion.Name} - ${suggestion.IDNumber}`}
    </div>
  );

  const selectData = (suggestion) => {
    setSeldata(suggestion);
    {
      props.GetData(suggestion);
    }
  };

  const onChange = (e, { newValue }) => {
    setInput(newValue);
  };

  const inputProps = {
    placeholder: "",
    value: input,
    onChange,
    className: "Autoinput",
  };

  return (
    <div>
      <div className="inputs">
        <label className="form-label">{`${props.inputdata}`}</label>
      </div>
      <Autosuggest
        suggestions={data}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={inputProps}
      />
      <div className="inputs">
        <label className="form-label">{`${props.inputname}`}</label>
      </div>
      <input placeholder={seldata.Name} className="Autoinput" readOnly />
    </div>
  );
};

export default AutoInput;
