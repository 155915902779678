import React from "react";
import { useRef, useState } from "react";
import "./canvas.css";
import SignaturePad from "react-signature-canvas";

const Canvas = (props) => {
  const canvas = useRef(null);
  const Save = () => {
    const base64 = canvas.current.toDataURL();
    props.GetData(base64);
  };

  const Clear = () => {
    canvas.current.clear();
    props.GetData("");
  };

  return (
    <div className="maincontainer">
      <div className="canvascontainer">
        <SignaturePad
          onEnd={Save}
          ref={canvas}
          canvasProps={{ className: "SignaturePad" }}
        />
      </div>
      <br />
      <button onClick={Clear} className="erase-button">Borrar</button>
    </div>
  );
};

export default Canvas;
